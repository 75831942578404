if (window.Zip && window.Zip.sustainabilitycalculator) {
	PetiteVue.createApp({
		formOptions: window.Zip.sustainabilitycalculator.options,
		formModels: window.Zip.sustainabilitycalculator.formModels,
		formCapacity: [],
		isHasResults: false,
		formOwnershipYears: 10,
		formData: {
			productclass: '',
			environment: window.Zip.sustainabilitycalculator.environment,
			model: window.Zip.sustainabilitycalculator.model,
			capacity: '',
			yearsowned: 10,
			mediaitemid: window.Zip.sustainabilitycalculator.mediaId,
		},
		resultData: {
			single_use_bottles: 0,
			kg_of_plastic: 0,
			kg_of_co2: 0,
			driven_by_car: 0,
			hours_of_led: 0,
			hours_by_plane: 0,
			trees_grown_10years: 0,
			comparison_notes: '',
		},
		mounted() {
			console.log("Mount start");
			this.setFormCapacityValues();//Setting intial value correctly.
			this.handleChange();
			console.log("Mount end");

		},
		get isHome() {
			console.log("isHome");

			return this.formData.environment === "Home";
		},
		get formCapacityValues() {
			console.log("formCapacityValues");

			this.formCapacity.splice(0); //reset;
			let _ = this;
			if (_.formData.environment === 'Home') {
				return [];
			}

			var withModel = window.Zip
				.sustainabilitycalculator
				.options.filter(x =>
					x.Product == _.formData.model
					&& x.Range == _.formData.environment);
			if (withModel.length > 0) {

				withModel.forEach(function (val) {
					_.formCapacity.push(val.Capacity);

				});
			}
			return this.formCapacity;
		},
		setFormCapacityValues() {
			console.log("setFormCapacityValues");
			console.log(this.formCapacityValues);

			if (!this.isHome)
				this.formData.capacity = this.formCapacityValues[0];
			this.formOwnershipYears = this.isHome ? 10 : 7;
			this.formData.yearsowned = this.formOwnershipYears;
		},
		resetResultData() {
			console.log("resetResultData");

			this.resultData.single_use_bottles = 'N/A';
			this.resultData.kg_of_plastic = 'N/A';
			this.resultData.kg_of_co2 = 'N/A';
			this.resultData.driven_by_car = 'N/A';
			this.resultData.hours_of_led = 'N/A';
			this.resultData.hours_by_plane = 'N/A';
			this.resultData.trees_grown_10years = 'N/A';
			this.resultData.comparison_notes = 'N/A';
		},
		environmentChange(e) {
			let _ = this;
			this.setFormCapacityValues();

			if (this.formData.environment !== "Home" && (this.formData.capacity === '' || !this.formCapacity.includes(this.formData.capacity))) {
				this.formData.capacity = this.formCapacity[0];
			}

			this.handleChange(null);
		},
		modelChange(e) {
			console.log("modelChange");
			this.setFormCapacityValues();
			this.handleChange(e);
		},
		handleChange(e) {
			console.log("handleChange");

			this.isHasResults = false;
			fetch('/api/sustainability/calculate', {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify(this.formData),
			})
				.then(async response => {
					const data = await response.json();

					if (data.IsError) {
						this.resetResultData();
					} else {
						this.resultData.single_use_bottles = data.PlasticBottleSavings;
						this.resultData.kg_of_plastic = data.PlasticSavingsKG;
						this.resultData.kg_of_co2 = data.CO2Savings;
						this.resultData.driven_by_car = data.CarKms;
						this.resultData.hours_of_led = data.LEDHours; //CarMiles;
						this.resultData.hours_by_plane = data.PlaneHours;
						this.resultData.trees_grown_10years = data.TreeSeeds;
						this.resultData.comparison_notes = data.ComparisonNotes;
						this.isHasResults = true;
					}
				})
				.catch(() => {
					this.resetResultData();
				});
		},

	}).mount("#calculatorContainer");
}
